<script>
import { orderBy, get } from "lodash";
// TODO - have a common formater helper, instead of using this everywhere.
// even better is a date pipe - with date types: short, long, etc...

import { format } from "date-fns";
import { hasActivity } from "@/utils/buy";
import CustomerBuyListItem from "./CustomerBuyListItem.vue";

import {
  SORT_ORDER,
  VxDataList,
  PaginationOptionsUtils,
  LocalPaginationUtils,
} from "@/core-ui";

export default {
  name: "CustomerDetailDialogSelling",
  components: {
    VxDataList,
    CustomerBuyListItem,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    buysRouteName: {
      type: String,
      required: true,
    },
    storeId: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    paginationOptions: PaginationOptionsUtils.buildDefaultPaginationOptions({
      pageSizes: [5, 25, 50, 100],
      itemsPerPage: 5,
      itemsPerPageStep: 5,
    }),
    sortOptions: PaginationOptionsUtils.buildDefaultSortOptions({
      sortOrder: SORT_ORDER.DESC,
      sortBy: "openDate",
    }),
  }),
  computed: {
    isSmallScreen() {
      // TODO - put this in some common lib
      return this.$vuetify.breakpoint.smAndDown;
    },
    itemsPaginated() {
      LocalPaginationUtils.updateOptionsFromLocalData(
        this.paginationOptions,
        this.items
      );
      return LocalPaginationUtils.paginateLocalItems(
        this.items,
        this.paginationOptions,
        this.sortOptions,
        {
          ignoreCase: true,
        }
      );
    },
    headers() {
      return [
        {
          label: "TransID",
          id: "transId",
          sortable: true,
          propertyPath: "transId",
        },
        {
          label: "Store",
          id: "store.name",
          sortable: true,
          propertyPath: "store.name",
        },
        {
          label: "Date",
          id: "openDate",
          sortable: true,
          propertyPath: "openDate",
        },
        {
          label: "Total",
          id: "totalAmount",
          sortable: true,
          align: "right",
          propertyPath: "totalAmount",
        },
        {
          label: "",
          id: "action",
          sortable: false,
          propertyPath: "action",
        },
      ];
    },
  },
  methods: {
    hasActivity,
    format,
    onShowMore() {
      if (this.isSmallScreen) {
        LocalPaginationUtils.fetchMoreData(this.paginationOptions);
      }
    },
    onPageUpdate(page) {
      LocalPaginationUtils.updatePage(this.paginationOptions, page);
    },
    onItemsPerPageUpdate(itemsPerPage) {
      LocalPaginationUtils.updateItemsPerPage(
        this.paginationOptions,
        itemsPerPage
      );
    },
    onSortUpdate(header) {
      LocalPaginationUtils.updateSort(
        this.sortOptions,
        this.paginationOptions,
        header.propertyPath
      );
    },
    showBuy(drsBuy) {
      if (drsBuy.store.id !== this.storeId) {
        // Not navigation to different store for now
        return;
      }

      const buyRoute = {
        name: this.buysRouteName,
        params: {
          buyId: drsBuy.buy.id,
        },
        preserveQuery: true,
      };

      this.$router.replace(buyRoute);
    },
  },
};
</script>

<template>
  <VxDataList
    ref="buysItemsBody"
    class="customer-buys-view"
    :headers="headers"
    :pagination-options="paginationOptions"
    :sort-options="sortOptions"
    @update:page="onPageUpdate"
    @update:itemsPerPage="onItemsPerPageUpdate"
    @update:sort="onSortUpdate"
    @showMore="onShowMore"
  >
    <template #body>
      <CustomerBuyListItem
        v-for="buy in itemsPaginated"
        :key="buy.id"
        :buy="buy"
        :store-id="storeId"
        @show="showBuy(buy)"
      />
    </template>
  </VxDataList>
</template>

<style></style>
