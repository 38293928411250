<template>
  <v-dialog ref="dialog" v-model="value" persistent width="400px">
    <!-- Set a reason -->
    <v-card v-if="flagged">
      <v-card-title class="text-h6" v-text="`Flag this customer`" />
      <v-card-text>
        <v-textarea
          v-model="localFlagReason"
          label="This customer should be flagged because"
          no-resize
          filled
          single-line
        ></v-textarea>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          text
          color="grey"
          data-test-ref="button-reason-cancel"
          @click="handleCancel"
          >Cancel</v-btn
        >
        <v-btn
          text
          color="primary"
          data-test-ref="button-reason-update"
          :loading="loading"
          @click.native="handleUpdate"
          >Flag</v-btn
        >
      </v-card-actions>
    </v-card>
    <!-- Are you sure this customer shouldn't be flagged? -->
    <v-card v-else>
      <v-card-title>
        <span class="text-h6" v-text="`Remove flag`" />
      </v-card-title>
      <v-card-text>
        <blockquote class="blockquote" v-text="flagReason"></blockquote>
      </v-card-text>
      <v-card-text
        v-text="`Are you sure you want to remove the flag from this customer?`"
      />
      <v-card-actions>
        <v-spacer />
        <v-btn
          text
          color="grey"
          data-test-ref="button-remove-cancel"
          @click.native="handleCancel"
          >Cancel</v-btn
        >
        <v-btn
          text
          data-test-ref="button-remove-remove"
          color="primary"
          :loading="loading"
          @click.native="handleUpdate"
          >Remove</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { UPDATE_CUSTOMER } from "@/graphql/customer/mutations.gql";
import { mapMutations } from "vuex";
export default {
  name: "CustomerDetailDialogInfoFlagDialog",
  props: {
    // The dialog controller, true/false
    value: {
      type: Boolean,
      default: undefined,
    },
    // Whether the customer is flagged or not
    flagged: {
      type: Boolean,
      default: undefined,
    },
    flagReason: {
      type: String,
      default: undefined,
    },
    // Customer id for using the UPDATE_CUSTOMER mutation
    customerId: {
      type: [String, Number],
      default: undefined,
    },
  },
  data() {
    return {
      loading: false,
      localFlagReason: this.flagReason,
    };
  },
  computed: {
    mutationVariables() {
      let variables = { id: this.customerId.toString() };
      return this.flagged
        ? Object.assign({}, variables, {
            flagged: this.flagged,
            flagReason: this.localFlagReason,
          })
        : Object.assign({}, variables, { flagged: false, flagReason: null });
    },
    queryVariables() {
      return {
        id: this.customerId.toString(),
        storeId: this.$route.params.storeId,
      };
    },
  },
  methods: {
    ...mapMutations("snackbar", ["showSnackbar"]),
    handleCancel() {
      this.hide();
    },
    handleUpdate() {
      this.loading = true;
      this.updateCustomer();
    },
    async updateCustomer() {
      try {
        await this.$apollo.mutate({
          mutation: UPDATE_CUSTOMER,
          variables: {
            input: { ...this.mutationVariables },
          },
        });
        // Dialog model update
        this.$emit("input", false);
      } catch (error) {
        // Let the user know of any errors
        this.showSnackbar({
          text: `An error occurred while updating customer. ${error}`,
        });
      }
      await this.$nextTick();
      this.localFlagReason = this.flagReason;
      this.loading = false;
    },
    hide() {
      this.$emit("input", false);
      this.$emit("cancel", false);
    },
  },
};
</script>
