<script>
import { VxDataListItem } from "@/core-ui";
import { format, parseISO } from "date-fns";
import { hasActivity } from "@/utils/buy";

export default {
  name: "CustomerSellItemDesktop",
  components: {
    VxDataListItem,
  },

  filters: {
    date(value) {
      if (value) {
        if (typeof value === "string") {
          return format(parseISO(value), "MM/dd/yyyy");
        } else {
          return format(value, "MM/dd/yyyy");
        }
      } else {
        return "";
      }
    },
  },
  inheritAttrs: false,
  props: {
    sell: {
      type: Object,
      default: () => ({}),
    },
    storeId: {
      type: String,
      default: undefined,
    },
  },

  methods: {
    hasActivity,
    showSell() {
      this.$emit("show", this.sell);
    },
  },
};
</script>

<template>
  <VxDataListItem
    v-bind="$attrs"
    class="rai-data item disabled"
    v-on="$listeners"
    @click.stop="showSell"
  >
    <template #desktop>
      <td>{{ sell.trxno }}</td>
      <td>{{ sell.store.name }}</td>
      <td>{{ sell.busDate | date }}</td>

      <td class="text-right">{{ $filters.dollars(sell.total) }}</td>
    </template>

    <template #mobile>
      <v-list-item-content class="coupon-name item__content pt-0">
        <v-list-item-title>
          <span class="font-weight-bold coupon-name-text" v-text="sell.trxno" />
        </v-list-item-title>
        <v-list-item-subtitle>
          <span class="font-italic text-caption">{{ sell.store.name }}</span>
        </v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-content class="item__content pt-0">
        <v-list-item-title>
          <span>{{ $filters.dollars(sell.total) }}</span>
        </v-list-item-title>
      </v-list-item-content>
      <v-list-item-action-text class="primary--text item__content">{{
        sell.busDate | date
      }}</v-list-item-action-text>
    </template>
  </VxDataListItem>
</template>

<style scoped>
.coupon-name-text {
  text-overflow: unset;
  word-break: break-word;
  white-space: normal;
}

.coupon-name {
  max-width: 12ch;
}

.item {
  padding-top: 8px;
  max-width: 100vw;
}

.item__content {
  align-self: start;
  padding-bottom: 8px;
}
</style>
