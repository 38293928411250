<template>
  <div>
    <v-card-text>
      <v-row class="pb-4" align="end">
        <v-col>
          <span
            v-if="!customer.fullName"
            class="grey--text"
            v-text="`Customer name`"
          />
          <flagged-name v-else :customer="customer"></flagged-name>
        </v-col>
        <v-col class="text-right">
          <span v-text="`Flag customer`"></span>
          <v-switch
            v-model="computedFlagged"
            :loading="switchLoading"
            class="mt-0 d-inline-flex ml-4"
            color="error"
            :ripple="false"
            hide-details
          />
        </v-col>
      </v-row>
      <v-divider />
      <v-row class="pt-4" align="center">
        <v-col sm="6">
          <v-icon class="mr-4" v-text="'$vuetify.icons.phone'"></v-icon>
          <span
            v-if="!customer.phoneNumber"
            class="grey--text"
            v-text="`Phone number`"
          ></span>
          <span v-else v-text="formatNumber(customer.phoneNumber)"></span>
        </v-col>
        <v-col sm="6">
          <v-icon class="mr-4" v-text="'$vuetify.icons.email'"></v-icon>
          <span
            v-if="!customer.email"
            class="grey--text"
            v-text="`Customer email`"
          ></span>
          <span v-else v-text="customer.email"></span>
        </v-col>
      </v-row>
    </v-card-text>
    <flag-dialog
      v-model="dialog"
      :flagged="localFlagged"
      :flag-reason="customer.flagReason"
      :customer-id="customer.id"
      @cancel="handleCancel"
      @update="handleUpdate"
    />
  </div>
</template>
<script>
import { formatNumber } from "@/utils/phonenumber";
import FlaggedName from "@/components/customers/FlaggedName";
import FlagDialog from "@/components/customers/CustomerDetailDialogInfoFlagDialog";
export default {
  name: "CustomerDetailDialogInfo",
  components: { FlaggedName, FlagDialog },
  props: {
    customer: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      loading: false,
      localFlagged: undefined,
      localFlagReason: undefined,
      dialog: false,
    };
  },
  computed: {
    computedFlagged: {
      get() {
        if (this.localFlagged !== undefined) {
          return this.localFlagged;
        }
        return this.customer.flagged;
      },
      set(v) {
        this.localFlagged = v;
      },
    },
    switchLoading() {
      return this.dialog;
    },
  },
  watch: {
    customer(v, o) {
      if (v.id !== o.id) {
        this.resetLocalFields();
      }
    },
    localFlagged(v, o) {
      // If the value changed and it doesn't equal
      // what is currently on the customer, it is
      // user interaction. Show the dialog.
      if (v !== this.customer.flagged) {
        this.showDialog();
      }
    },
  },
  methods: {
    formatNumber,
    resetLocalFields() {
      this.localFlagged = undefined;
      this.localFlagReason = undefined;
    },
    showDialog() {
      this.dialog = true;
    },
    handleCancel(v) {
      this.localFlagged = this.customer.flagged;
      this.dialog = false;
    },
    handleUpdate(v) {
      console.log("CustomerDetailDialogInfo.handleUpdate: implement me.", v);
    },
  },
};
</script>
