<script>
import VxDialogView from "@/components/vx/VxDialogView";
import FlaggedName from "@/components/customers/FlaggedName.vue";
import { formatNumber } from "@/utils/phonenumber";
import { CUSTOMER_DETAIL } from "@/graphql/customer/queries.gql";
import { MESSAGE_CREATE } from "@/views/Texting/AppView/graphql";
import { mapMutations } from "vuex";
import DrsRecords from "@/components/customers/CustomerDetailDialogDrsRecordsFn.vue";
import Shopping from "@/components/customers/CustomerDetailDialogShopping.vue";
import Selling from "@/components/customers/CustomerDetailDialogSelling.vue";
import MessageThread from "@/views/Texting/AppView/components/MessageThread.vue";
import MessageInput from "@/views/Texting/AppView/components/MessageThreadMessageInput.vue";
import CustomerInfo from "@/components/customers/CustomerDetailDialogInfo.vue";

import { get } from "lodash";

export default {
  rightPanelHeight: "calc(70vh - 64px)",
  components: {
    CustomerInfo,
    VxDialogView,
    FlaggedName,
    DrsRecords,
    Shopping,
    Selling,
    MessageThread,
    MessageInput,
  },
  props: {
    customerId: {
      type: [String, Number],
      default: -1,
    },
    expanded: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Boolean,
      default: false,
    },
    buysRouteName: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    tabs: "info",
    loading: false,
    sendLoading: false,
    customer: {},
  }),
  apollo: {
    customer: {
      watchLoading(isLoading) {
        this.loading = isLoading;
      },
      query: CUSTOMER_DETAIL,
      variables() {
        return { ...this.variables };
      },
      skip() {
        return this.skip;
      },
    },
  },
  computed: {
    drsBuys() {
      return get(this.customer, "drsBuys", []);
    },
    drsTransactions() {
      return get(this.customer, "drsTransactions", []);
    },
    drsCustomers() {
      return get(this.customer, "drsCustomers", []);
    },
    customerFlagged() {
      let flagged;
      if (this.customer) {
        flagged =
          (this.customer.hasOwnProperty("customer") &&
            get(this.customer, "customer.flagged", false)) ||
          get(this.customer, "flagged", false);
      } else {
        if (this.flagOnBuy) {
          flagged = get(this.buy, "customer_flagged", false);
        } else {
          flagged = get(this.buy, "customer.flagged", false);
        }
      }
      return flagged;
    },
    flagReason() {
      if (!this.customerFlagged) return;

      return (
        get(this, "customer.flagReason") || // props.customer is a customer
        get(this, "customer.flag_reason") ||
        get(this, "customer.customer.flagReason") || // props.customer is a drsCustomer
        get(this, "customer.customer.flag_eason") ||
        "No reason specified"
      );
    },
    variables() {
      return {
        storeId: this.$route.params.storeId,
        id: this.customerId,
      };
    },
    skip() {
      return !this.customerId || this.customerId < 0;
    },
    height() {
      return this.$vuetify.breakpoint.mdAndUp && "50vh";
    },
    classes() {
      return ["rai-dialog", `rai-dialog-${this.$vuetify.breakpoint.name}`].join(
        " "
      );
    },
  },
  // TODO - see if both beforeRouteEnter and beforeRouteUpdate are needed
  beforeRouteEnter(to, from, next) {
    // TODO - try to get this from props
    next((vm) => {
      if (
        to.query &&
        to.query.customerDetailTab &&
        to.query.customerDetailTab !== vm.tabs
      ) {
        vm.tabs = to.query.customerDetailTab;
        vm.changeTab(to.query.customerDetailTab);
      }
    });
  },
  beforeRouteUpdate(to, from, next) {
    // TODO - try to get this from props
    if (
      to.query &&
      to.query.customerDetailTab &&
      to.query.customerDetailTab !== this.tabs
    ) {
      this.tabs = to.query.customerDetailTab;
      this.changeTab(to.query.customerDetailTab);
    }

    next();
  },
  methods: {
    formatNumber,
    ...mapMutations("snackbar", ["showSnackbar"]),
    hide() {
      this.$emit("input", false);
      this.$emit("update:expanded", false);
      this.customer = {};
    },
    sendMessage(body) {
      this.sendLoading = true;
      const variables = {
        input: {
          messageThreadId: this.customer.messageThread.id,
          body,
        },
      };
      this.$apollo
        .mutate({
          mutation: MESSAGE_CREATE,
          variables,
          update: (
            store,
            {
              data: {
                createMessage: { message },
              },
            }
          ) => {
            const data = store.readQuery({
              query: CUSTOMER_DETAIL,
              variables: { ...this.variables },
            });
            data.customer.messageThread.messages.push(message);
            store.writeQuery({
              query: CUSTOMER_DETAIL,
              data,
              variables: { ...this.variables },
            });
          },
        })
        .catch((error) => {
          this.showSnackbar({
            text: `An error occurred while sending message. ${error}`,
          });
        })
        .then(() => {
          this.sendLoading = false;
        });
    },
    changeTab(tabName) {
      this.$router.replace({
        name: this.$route.name,
        query: {
          customerDetailTab: tabName,
        },
        preserveQuery: true,
      });
    },
  },
};
</script>

<template>
  <VxDialogView
    :v-size="'huge'"
    :h-size="'huge'"
    class="customer-detail-dialog"
    :retain-focus="false"
    :error-toolbar="customerFlagged"
    :min-height="900"
  >
    <template #toolbar-title>
      <v-toolbar-title
        class="d-flex justify-start align-center flex-shrink-0 flex-grow-1"
      >
        <FlaggedName :customer="customer" :white="true" />
        <span v-if="customerFlagged">({{ flagReason }})</span>
      </v-toolbar-title>
    </template>
    <template #toolbar-extension>
      <v-tabs
        v-model="tabs"
        grow
        centered
        show-arrows
        class="raiTabs"
        background-color="transparent"
        @change="changeTab"
      >
        <v-tab href="#info">Contact</v-tab>
        <v-tab href="#records">Drs Records</v-tab>
        <v-tab href="#shopping">Sales</v-tab>
        <v-tab href="#selling">Buys</v-tab>
        <v-tab href="#messages">Messages</v-tab>
      </v-tabs>
    </template>
    <v-tabs-items
      v-model="tabs"
      touchless
      :grow="true"
      :height="'auto'"
      class="customer-detail-dialog-tabs"
    >
      <v-tab-item value="info">
        <CustomerInfo :customer="customer" />
      </v-tab-item>
      <v-tab-item value="records" fill-height>
        <DrsRecords :items="drsCustomers" />
      </v-tab-item>
      <v-tab-item value="shopping">
        <Shopping :items="drsTransactions" />
      </v-tab-item>
      <v-tab-item value="selling">
        <Selling
          :items="drsBuys"
          :buys-route-name="buysRouteName"
          :store-id="$route.params.storeId"
        />
      </v-tab-item>
      <v-tab-item value="messages" class="fill-height">
        <!-- TODO have a custom class for errors size and colour -->
        <div v-if="!customer.messageThread" class="text-center">
          Customer does not have phone number or phone number is invalid.
        </div>
        <div v-else class="messages">
          <MessageThread
            :items="
              (customer.messageThread && customer.messageThread.messages) || []
            "
          />
          <MessageInput :loading="sendLoading" @send="sendMessage" />
        </div>
      </v-tab-item>
    </v-tabs-items>
  </VxDialogView>
</template>

<style lang="scss">
// TODO - check messages input and remove row when it's not coliding with other places messages are used
// - also remove the bottom padding from tabs is possible
.customer-detail-dialog-tabs {
  height: 100%;
  padding-bottom: 20px;
  .message-input {
    margin-top: 0;
    height: auto;
  }
}

.messages {
  display: grid;
  grid-template-rows: 1fr 52px;
  height: inherit;
}
</style>
