<script>
import CustomerSellListItem from "./CustomerSellListItem.vue";
import {
  SORT_ORDER,
  VxDataList,
  PaginationOptionsUtils,
  LocalPaginationUtils,
} from "@/core-ui";

export default {
  name: "CustomerDetailDialogShopping",
  components: {
    VxDataList,
    CustomerSellListItem,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    paginationOptions: PaginationOptionsUtils.buildDefaultPaginationOptions({
      pageSizes: [5, 25, 50, 100],
      itemsPerPage: 5,
      itemsPerPageStep: 5,
    }),
    sortOptions: PaginationOptionsUtils.buildDefaultSortOptions({
      sortOrder: SORT_ORDER.DESC,
      sortBy: "busDate",
    }),
  }),
  computed: {
    isSmallScreen() {
      // TODO - put this in some common lib
      return this.$vuetify.breakpoint.smAndDown;
    },
    headers() {
      return [
        {
          propertyPath: "trxno",
          label: "TransID",
          sortable: true,
        },
        {
          propertyPath: "store.name",
          label: "Store",
          sortable: true,
        },
        {
          propertyPath: "busDate",
          label: "Date",
          sortable: true,
        },
        {
          propertyPath: "total",
          label: "Total",
          sortable: true,
          align: "right",
        },
      ];
    },
    itemsPaginated() {
      LocalPaginationUtils.updateOptionsFromLocalData(
        this.paginationOptions,
        this.items
      );
      return LocalPaginationUtils.paginateLocalItems(
        this.items,
        this.paginationOptions,
        this.sortOptions,
        {
          ignoreCase: true,
        }
      );
    },
  },
  methods: {
    onShowMore() {
      if (this.isSmallScreen) {
        LocalPaginationUtils.fetchMoreData(this.paginationOptions);
      }
    },
    onPageUpdate(page) {
      LocalPaginationUtils.updatePage(this.paginationOptions, page);
    },
    onItemsPerPageUpdate(itemsPerPage) {
      LocalPaginationUtils.updateItemsPerPage(
        this.paginationOptions,
        itemsPerPage
      );
    },
    onSortUpdate(header) {
      LocalPaginationUtils.updateSort(
        this.sortOptions,
        this.paginationOptions,
        header.propertyPath
      );
    },
  },
};
</script>

<template>
  <VxDataList
    ref="sellsItemsBody"
    class="customer-sales-view"
    :headers="headers"
    :pagination-options="paginationOptions"
    :sort-options="sortOptions"
    @update:page="onPageUpdate"
    @update:itemsPerPage="onItemsPerPageUpdate"
    @update:sort="onSortUpdate"
    @showMore="onShowMore"
  >
    <template #body>
      <CustomerSellListItem
        v-for="sell in itemsPaginated"
        :key="sell.id"
        :sell="sell"
      />
    </template>
  </VxDataList>
</template>

<style></style>
